var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "table",
    attrs: {
      headers: _vm.headers,
      items: _vm.inverters,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "items-per-page": -1,
      "hide-default-footer": "",
      "mobile-breakpoint": "0",
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "item.name",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "d-flex align-center ms-2" }, [
              _c(
                "span",
                { staticClass: "text-sm font-weight-normal text-body" },
                [_vm._v(" " + _vm._s(item.inverter_name) + " ")]
              ),
            ]),
          ]
        },
      },
      {
        key: "item.thirty_day_health",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "v-progress-linear",
              {
                staticStyle: { width: "100px" },
                attrs: {
                  value: item.thirty_day_health,
                  height: "15",
                  "background-color": _vm.progressBackground(
                    item.thirty_day_health
                  ),
                  color: _vm.progressBarColor(item.thirty_day_health),
                },
              },
              [
                _c("div", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(Math.ceil(item.thirty_day_health)) + "%"),
                ]),
              ]
            ),
          ]
        },
      },
      {
        key: "item.day_health",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "div",
              { staticClass: "wider-slot" },
              [
                _c(
                  "v-chip",
                  { attrs: { color: _vm.getColor(item.day_health), dark: "" } },
                  [_vm._v(" " + _vm._s(Math.round(item.day_health)) + " ")]
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "item.q_thirty_percentage",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "v-progress-linear",
              {
                staticStyle: { width: "100px" },
                attrs: {
                  value: item.q_thirty_percentage,
                  height: "15",
                  "background-color": _vm.progressBackground(
                    item.q_thirty_percentage
                  ),
                  color: _vm.progressBarColor(item.q_thirty_percentage),
                },
              },
              [
                _c("div", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(Math.ceil(item.q_thirty_percentage)) + "%"),
                ]),
              ]
            ),
          ]
        },
      },
      {
        key: "item.q_percentage",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "v-chip",
              { attrs: { color: _vm.getColor(item.q_percentage), dark: "" } },
              [_vm._v(" " + _vm._s(Math.round(item.q_percentage)) + " ")]
            ),
          ]
        },
      },
      {
        key: "item.monitoring_url",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          item.master_id
                            ? _c(
                                "img",
                                _vm._g(
                                  {
                                    attrs: {
                                      src: require("@/assets/img/" +
                                        item.monitoring_system +
                                        ".png"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.openWindow(
                                          item.monitoring_url
                                        )
                                      },
                                    },
                                  },
                                  on
                                )
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [_c("span", [_vm._v("Monitoring Link")])]
            ),
            [
              !item.monitoring_url && item.master_id
                ? _c("v-icon", { attrs: { disabled: "" } }, [
                    _vm._v("mdi-web-box"),
                  ])
                : _vm._e(),
            ],
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }